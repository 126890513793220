<script setup>
import { Head, usePage } from "@inertiajs/vue3";
import SageLogin from "@/Pages/Auth/Sage/Login.vue";
import NumnumLogin from "@/Pages/Auth/Numnum/Login.vue";

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const page = usePage();
</script>

<template>
    <Head title="Log in" />
    <!-- numnum login -->
    <numnum-login v-if="page.props.theme === 'numnum'" />

    <!-- sage login -->
    <sage-login
        v-else-if="page.props.theme === 'sage'"
        :can-reset-password="canResetPassword"
        :status="status"
    />
</template>
<style scoped lang="scss">
.font-11 {
    font-size: 11px;
}
</style>
